import config from '../config/website_config.json';

let JsonPayload
export async function apiPostRequest(content: any, urlpath: string) {
    const urlPlusPath = config.url.API_URL + urlpath;
    JsonPayload= JSON.stringify({
        location: content.location,
        massage: content.massage,
        checked: content.check,
        penp: content.penp,
        id: content.id
    })
    if (content.location === undefined) {
        JsonPayload= JSON.stringify({
            massage: content.massage,
            checked: content.check,
            penp: content.penp,
            id: content.id
        })
    }
    try {
        const response = await fetch(urlPlusPath, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JsonPayload,
        })
        await response.json();
        return
    } catch (error) {
        console.error('Error:', error);
        return error
    }
}

export async function apigetRequest(urlpath: string) {
    const urlPlusPath = config.url.API_URL + urlpath;
    try {
        const response = await fetch(urlPlusPath, {
            method: 'Get',
            headers: {'Content-Type': 'application/json'}
        })
        return await response.json();
    } catch (error) {
        console.error('Error:', error);
        return error
    }
}
