import React from "react";

import {InputForm} from "../Elements/InputForm";
import Header from "../Elements/Header";

import '../css/style.css';
import TopNavigation from "../Elements/TopNavigation";

export default function CreateMiracles(){
    return(
        <>
            <main className={"index"}>
                <section className="header-menu-wrapper">
                    <header className="header-section">
                        <Header />
                    </header>
                </section>
                <section className="location2-prayer-container">
                    <menu className="menu-section">
                        {TopNavigation("","Go back to the prayer wall")}
                    </menu>
                </section>
                <section className="create-miracal">
                    <InputForm />
                </section>
            </main>
        </>
    )
}