import React, { ChangeEvent, FormEvent, useRef, useState } from "react";
import { apiPostRequest } from "../Comminucations/API";
import { useNavigate } from "react-router-dom";
import FromTextP from "./FromTextP";

export function InputForm() {
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({
        location: undefined,
        massage: undefined,
        penp: undefined,
    });

    const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    const handleTextareaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));

        // Update the textarea height based on scrollHeight
        if (textareaRef.current) {
            textareaRef.current.style.height = "auto"; // Reset height to auto
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    };

    const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
    };

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        apiPostRequest(inputs, "/msg").then();
        navigate("/");
    };

    return (
        <>
            <section className="under-form">
                {FromTextP('first-text',"Please fill in your prayer request or praise report below.")}
                <br/>
                {FromTextP('second-text',"We will pray for you collectively on Sunday, and people visiting the online prayer wall will pray for you during the week.")}
            </section>
            <form onSubmit={handleSubmit} className="form-container">
                <label className="header">I have a...</label>
                <section className="radio-container">
                    <section>
                        <input
                            className="inputleftdod"
                            type="radio"
                            name="penp"
                            value="Prayer"
                            checked={inputs.penp === "Prayer"}
                            onChange={handleRadioChange}
                            required={true}
                        />
                        <label className="label-input" htmlFor="Prayer">
                            Prayer request
                        </label>
                    </section>
                    <section>
                        <input
                            className="inputleftdod"
                            type="radio"
                            name="penp"
                            value="Praise"
                            checked={inputs.penp === "Praise"}
                            onChange={handleRadioChange}
                            required={true}
                        />
                        <label className="label-input" htmlFor="Praise">
                            Praise report
                        </label>
                    </section>
                </section>
                <textarea
                    ref={textareaRef}
                    className="inputleft"
                    name="massage"
                    placeholder="Please pray for... / I am thanking God for..."
                    value={inputs.massage || ""}
                    onChange={handleTextareaChange}
                    required={true}
                    spellCheck={true}
                />
                <br />
                <label className="header">Campus</label>
                <section className="radio-container">
                    <section>
                        <input
                            className="inputleftdod"
                            type="radio"
                            name="location"
                            value="Amsterdam"
                            checked={inputs.location === "Amsterdam"}
                            onChange={handleRadioChange}
                            required={true}
                        />
                        <label className="label-input" htmlFor="Amsterdam">
                            Amsterdam
                        </label>
                    </section>
                    <section>
                        <input
                            className="inputleftdod"
                            type="radio"
                            name="location"
                            value="Rotterdam"
                            checked={inputs.location === "Rotterdam"}
                            onChange={handleRadioChange}
                            required={true}
                        />
                        <label className="label-input" htmlFor="Rotterdam">
                            Rotterdam
                        </label>
                    </section>
                    <section>
                        <input
                            className="inputleftdod"
                            type="radio"
                            name="location"
                            value="Brussel"
                            checked={inputs.location === "Brussel"}
                            onChange={handleRadioChange}
                            required={true}
                        />
                        <label className="label-input" htmlFor="Brussel">
                            Brussel
                        </label>
                    </section>
                </section>
                <input type="submit" />
            </form>
        </>
    );
}
