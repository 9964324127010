import React, {useEffect} from "react";
import Header from "../Elements/Header";
import MiracleInfo from "../Elements/MiracleInfo";
import * as cookie from 'cookie';
import '../css/style.css';


export function makeId(length: number): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result
}


export default function Home(){
        useEffect(() => {
            const cookieOptions = {
                maxAge: 30 * 24 * 60 * 60, // Cookie expiration time in seconds (1 day)
                domain: 'monthofmiracles.nl', // Cookie domain (optional
            };
            document.cookie = cookie.serialize('monthofmiracles', makeId(32), cookieOptions);
        }, []);
    return(
        <>
            <main className={"index"}>
                <section className="header-menu-wrapper">
                    <header className="header-section">
                        <Header />
                    </header>
                </section>
            <section className="section-miracles">
                <MiracleInfo />
            </section>
            </main>
        </>
    )
}