import React from "react";

const FromTextP = (style: string, text:string) => {
    return (
            <p className={style}>
                {text}
            </p>
    )
}
export default FromTextP;
