import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/style.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from "react-router-dom";

//paga routes
import Home from './pages/Home';
import CreateMiracles from "./pages/CreateMiracles";
import CheckMiracles from "./pages/CheckMiracles";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

export default function App(){
    return (
        <BrowserRouter>
            <Routes>
                <Route>
                    <Route index element={<Home />} />
                    <Route path="/createmiracle" element={<CreateMiracles />} />
                    <Route path="/checkmiracles" element={<CheckMiracles />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
