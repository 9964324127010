import React, { useState, useEffect } from 'react';
import "../css/style.css"
import config from '../config/website_config.json';
import TopNavigation from "./TopNavigation";
import * as cookie from 'cookie';

interface Miracle {
    _id: string;
    massage: string;
    location: string;
    penp: string;
    fbresponse: number;
}

function MiracleInfo() {
    const [miracleData, setMiracleData] = useState<Miracle[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<string>('Amsterdam');

    function getCookieByName(name: string): string | null {
        const cookies = cookie.parse(document.cookie);
        return cookies[name] || null;
    }

    useEffect(() => {
        fetchMiracleData(selectedLocation).then();
        const interval = setInterval(() => {
            fetchMiracleData(selectedLocation).then();
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, [selectedLocation]);

    const fetchMiracleData = async (location: string) => {
        try {
            const response = await fetch(config.url.API_URL + "/getcmiracles" + "?location=" + location + "&checked=true");
            const responseData = await response.json();

            if (Array.isArray(responseData)) {
                setMiracleData(responseData);
            } else {
                console.error('Fetched data is not an array:', responseData);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleLocationChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedLocation(event.target.value);
    };

    const handleEmojiClick = async (miracleId: string) => {
        // Find the Miracle object by ID
        const miracle = miracleData.find((m) => m._id === miracleId);

        if (miracle) {
            try {
                // Update the count locally first
                const updatedMiracleData = miracleData.map((m) => {
                    if (m._id === miracleId) {
                        return {
                            ...m,
                            fbresponse: m.fbresponse + 1,
                        };
                    }
                    return m;
                });

                setMiracleData(updatedMiracleData);

                // Send API request to update the count on the server
                const response = await fetch(`${config.url.API_URL}/feedback`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id: miracle._id,
                        cookieid: getCookieByName('monthofmiracles'),
                    }),
                });

                if (!response.ok) {
                    console.error('Failed to send feedback');
                    // Revert the local count update if the server request fails
                    setMiracleData(miracleData);
                }
            } catch (error) {
                console.error('Error sending feedback:', error);
                // Revert the local count update if there's an error
                setMiracleData(miracleData);
            }
        }
    };

    return (
        <>
            <section className={"miracles"}>
                {miracleData.map((miracledata, index) => (
                    <section className={`card ${miracledata.penp === 'Prayer' ? 'card-default' : `card-${miracledata.penp}`}`} key={index}>
                        <p className={`${miracledata.penp === 'Prayer' ? 'text-default' : `text-${miracledata.penp}`}`} >{miracledata.penp === 'Prayer' ? `Can you pray with me for:` : `Let's Praise God for:`}</p>
                        <section className="content">
                                <p>{miracledata.massage}</p>
                            <section className="emoji-container" onClick={() => handleEmojiClick(miracledata._id)}>
                                {miracledata.penp === 'Prayer' ? '🙏 ' : '🙌 '}
                                <span>
                                    {miracledata.fbresponse === 0 ? ' ' : miracledata.fbresponse > 99 ? '99+' : miracledata.fbresponse}
                                </span>
                            </section>
                        </section>
                    </section>
                ))}
            </section>
            <section className="location-prayer-container">
                <menu className="menu-section">
                    {TopNavigation("createmiracle", "Submit\nPrayer/Praise")}
                </menu>
                <section className="location-container">
                    <select value={selectedLocation} onChange={handleLocationChange}>
                        <option value="All">All</option>
                        <option value="Amsterdam">Amsterdam</option>
                        <option value="Rotterdam">Rotterdam</option>
                        <option value="Brussel">Brussel</option>
                    </select>
                </section>
            </section>
        </>
    );
}

export default MiracleInfo;
