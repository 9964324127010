import React from "react";

import Header from "../Elements/Header";

import '../css/style.css';
import CheckMiracle from "../Elements/CheckMiracle";

export default function checkMiracles(){
    return(
        <>
            <main className={"index"}>
                <section className="header-menu-wrapper">
                    <header className="header-section">
                        <Header />
                    </header>
                </section>
                <section className={"section-miracles"}>
                    <CheckMiracle />
                </section>
            </main>
        </>
    )
}