import React from "react";
import { Outlet } from "react-router-dom";

const topNavigation = (location:string, text:string) => {
    return (
        <>
                <nav className="navigation">
                    <ul className={"topnav"}>
                        <li className="right"><a href= {"/"+location}>{text}</a></li>
                    </ul>
                    <Outlet />
                </nav>
        </>
    )
};
export default topNavigation;