import React, { useEffect, useState } from "react";
import '../css/style.css';
import config from "../config/website_config.json";

interface Item {
    _id: string;
    massage: string;
}

export default function CheckMiracle() {
    const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
    const [selectedItemMsg, setSelectedItemMsg] = useState<string>("");
    const [selectedLocation, setSelectedLocation] = useState<string>('Amsterdam');
    const [allLocationItems, setAllLocationItems] = useState<Item[]>([]);
    const [items, setItems] = useState<Item[]>([]);

    const [editingAllLocation, setEditingAllLocation] = useState(false); // Track editing mode for All Location

    useEffect(() => {
        fetchData(selectedLocation).then();
        fetchAllLocationMiracles(selectedLocation).then();

        const intervalId = setInterval(() => {
            fetchData(selectedLocation).then();
            fetchAllLocationMiracles(selectedLocation).then();
        }, 10000);

        return () => clearInterval(intervalId);
    }, [selectedLocation]);

    const fetchData = async (location: string) => {
        try {
            const response = await fetch(config.url.API_URL + "/miracles" + "?location=" + location + "&checked=false");
            if (response.ok) {
                const data: Item[] = await response.json();
                setItems(data);
            } else {
                console.error("Error fetching data:", response.statusText);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const fetchAllLocationMiracles = async (location: string) => {
        try {
            const response = await fetch(config.url.API_URL + "/miracles" + "?location=" + location + "&checked=All");
            if (response.ok) {
                const data: Item[] = await response.json();
                setAllLocationItems(data);
            } else {
                console.error("Error fetching all location miracles:", response.statusText);
            }
        } catch (error) {
            console.error("Error fetching all location miracles:", error);
        }
    };

    const handleItemClick = (itemId: string, msg: string, editAllLocation: boolean) => {
        setSelectedItemId(itemId);
        setSelectedItemMsg(msg);
        setEditingAllLocation(editAllLocation);
    };

    const handleUpdateItem = async () => {
        if (selectedItemId) {
            const updatedItem = { id: selectedItemId, massage: selectedItemMsg, check: true };
            try {
                const response = await fetch(config.url.API_URL + "/msg", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(updatedItem)
                });

                if (response.ok) {
                    setSelectedItemId(null);
                    if (editingAllLocation) {
                        fetchAllLocationMiracles(selectedLocation);
                    } else {
                        fetchData(selectedLocation);
                    }
                } else {
                    console.error("Error updating item:", response.statusText);
                }
            } catch (error) {
                console.error("Error updating item:", error);
            }
        }
    };

    const handleUpdateItemDelete = async () => {
        if (selectedItemId) {
            const updatedItem = { id: selectedItemId};
            try {
                const response = await fetch(config.url.API_URL + "/removemiracle", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(updatedItem)
                });
                if (response.ok) {
                    setSelectedItemId(null);
                    if (editingAllLocation) {
                        fetchAllLocationMiracles(selectedLocation);
                    } else {
                        fetchData(selectedLocation);
                    }
                } else {
                    console.error("Error updating item:", response.statusText);
                }
            } catch (error) {
                console.error("Error updating item:", error);
            }
        }
    };

    const handleLocationChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedLocation(event.target.value);
        setEditingAllLocation(false); // Reset editing mode when location changes
    };

    const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setSelectedItemMsg(e.target.value);
        scaleTextarea(e.target);
    };

    const scaleTextarea = (textareaElement: HTMLTextAreaElement) => {
        const inputValue = textareaElement.value;
        const inputLength = inputValue.length;
        const minHeight = 100;
        const maxHeight = 300;

        const newHeight = Math.min(maxHeight, Math.max(minHeight, inputLength * 10));

        textareaElement.style.height = newHeight + "px";
    };

    return (
        <>
            <section className={"check-location"}>
                <select value={selectedLocation} onChange={handleLocationChange}>
                    <option value="All">All</option>
                    <option value="Amsterdam">Amsterdam</option>
                    <option value="Rotterdam">Rotterdam</option>
                    <option value="Brussel">Brussel</option>
                </select>
            </section>
            <section className={"miracles-check"}>
                <h2>List of Miracles</h2>
                <ul>
                    {items.map((item) => (
                        <li
                            key={item._id}
                            onClick={() => handleItemClick(item._id, item.massage, false)} // Set editAllLocation to false
                            className={selectedItemId === item._id ? "selected" : ""}
                        >
                            {item.massage}
                        </li>
                    ))}
                </ul>
                <section className="details">
                    {selectedItemId && !editingAllLocation && (
                        <section>
                            <h2>Edit Miracle</h2>
                            <textarea
                                value={selectedItemMsg}
                                onChange={handleTextareaChange}
                            />
                            <section className="button-container">
                                <button onClick={handleUpdateItem}>Update Miracle</button>
                                <button onClick={handleUpdateItemDelete}>Delete Miracle</button>
                            </section>
                        </section>
                    )}
                </section>
            </section>
            <br />
            <br />
            <br />

            <section className={"miracles-check"}>
                <h2>All Miracles on Location</h2>
                <ul>
                    {allLocationItems.map((item) => (
                        <li
                            key={item._id}
                            onClick={() => handleItemClick(item._id, item.massage, true)} // Set editAllLocation to true
                            className={selectedItemId === item._id ? "selected" : ""}
                        >
                            {item.massage}
                        </li>
                    ))}
                </ul>
                <section className="details">
                    {selectedItemId && editingAllLocation && (
                        <section>
                            <h2>Edit Miracle</h2>
                            <textarea
                                value={selectedItemMsg}
                                onChange={handleTextareaChange}
                            />
                            <section className={"button-container"}>
                            <button onClick={handleUpdateItem}>Update Miracle</button>
                            <button onClick={handleUpdateItemDelete}>Delete Miracle</button>
                            </section>
                        </section>
                    )}
                </section>
            </section>
            <br />
            <br />
        </>
    );
}
