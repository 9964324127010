import React from "react";

const header = () => {
    return (
        <>
            <a href="/">
                <img src="MonthOfmiracles23.png" alt="MonthOfmiracles Title"></img>
            </a>
    </>
    )
}
export default header;